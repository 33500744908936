import { createGlobalStyle } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import 'react-multi-carousel/lib/styles.css';
import tinycolor from 'tinycolor2';

const tinyChangeRate = 7.5;

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: initial;
  }

  body {
    color: #0F1412;
    background-color: #f0f0f0;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  strong {
    font-weight: 600;
  }

  h1 {
    font-weight: 600;
    font-size: 32px;

    ${media.lg`
      font-size: 48px;
    `}
  }

  h2 {
    font-weight: 600;
    font-size: 34px;

    ${media.lg`
      font-size: 44px;
    `}
  }

  h3 {
    font-weight: 600;
    font-size: 36px;
  }

  h4 {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.25;
    margin-bottom: 8px;
  }

  h5 {
    font-weight: 600;
    font-size: 22px;
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  .mt-32 {
    margin-top: 32px;
  }

  img {
    margin-bottom: 0;
  }

  .ReactModal__Overlay {
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  p a {
    color: #ECB301;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .hiddenLgUp {
    display: block;

    ${media.lg`
      display: none;
    `}
  }

  .hiddenLgDown {
    display: none;

    ${media.lg`
      display: block;
    `}
  }
`;

export const gridTheme = {
  breakpoints: {
    // defaults below
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 16, // default 15
  },
  col: {
    padding: 16, // default 15
  },
  container: {
    padding: 16, // default 15
    margin: 0,
    maxWidth: {
      // defaults below
      xl: 1320,
    },
  },
};

export default {
  palette: {
    primary: {
      main: '#4e6d9d',
      dark: tinycolor('#4e6d9d').darken(tinyChangeRate).toHexString(),
    },
    secondary: {
      main: '#CBD0D2',
      dark: tinycolor('#CBD0D2').darken(tinyChangeRate).toHexString(),
    },
    text: {
      primary: '#0F1412',
    },
    success: {
      light: tinycolor('#99CC00').lighten(tinyChangeRate).toHexString(),
      main: '#99CC00',
      dark: tinycolor('#99CC00').darken(tinyChangeRate).toHexString(),
    },
    error: {
      light: tinycolor('#CD282B').lighten(tinyChangeRate).toHexString(),
      main: '#CD282B',
      dark: tinycolor('#CD282B').darken(tinyChangeRate).toHexString(),
    },
    warning: {
      light: tinycolor('#BE5A00').lighten(tinyChangeRate).toHexString(),
      main: '#BE5A00',
      dark: tinycolor('#BE5A00').darken(tinyChangeRate).toHexString(),
    },
    grey: {
      dark: '#E5E5E5',
      50: '#fafafa',
      100: '#f0f0f0',
      200: '#eeeeee',
      300: '#E5E5E5',
      400: 'lightgrey',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#0F1412',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
  },
  borders: {
    radius: '0px',
  },
  shadows: {
    card: '0px 5px 50px 12px rgba(0, 0, 0, 0.05)',
    image: '0px 5px 50px 12px rgba(0, 0, 0, 0.2)',
  },
  distances: {
    fixedFooterHeight: 50,
  },
  ...gridTheme,
};

require('lazysizes');
const React = require('react');
const { ThemeProvider } = require('styled-components');
const theme = require('./src/utils/theme').default;
const { gridTheme } = require('./src/utils/theme');
const { GlobalStyle } = require('./src/utils/theme');
const { ParallaxProvider } = require('react-scroll-parallax');
const { GridThemeProvider } = require('styled-bootstrap-grid');

exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <GridThemeProvider gridTheme={gridTheme}>
          <GlobalStyle />
          {element}
        </GridThemeProvider>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
